import {
    PRODUCT_SEARCH, LOCATION_LIST, DEALER_BY_LOCATION, CREW_LEADER_LIST,
    WORKORDER_LIST, VIEW_WORKORDER_LIST, WORKORDER_DETAILS, PRODUCT_DETAILS_WITH_VENDOR,
    DASBOARD_WORKORDER_LIST, BACKLOG_LIST, LOCATION_DEALER_DETAILS_OF_WORKORDER, RECENT_ORDERS, RESET_PICKER_STATE
} from '../../constants/Actiontypes';

const initialState = {
    products: [], locations: [], dealers: [], crewleaders: []
    , workOrderList: [], totalCount: 0, viewWorkOrderList: [], workorderDetails: {}, selectedProduct: {},
    dashboardWorkOrderList: [], backlogList: [], workorderInitialDetails: {}, recentOrders: []
};
const workorderReducer = (state = initialState, action) => {

    switch (action.type) {
        case PRODUCT_SEARCH:
            return { ...state, products: action.Payload }
        case LOCATION_LIST:
            return { ...state, locations: action.Payload }
        case DEALER_BY_LOCATION:
            return { ...state, dealers: action.Payload }
        case CREW_LEADER_LIST:
            return { ...state, crewleaders: action.Payload }
        case WORKORDER_LIST:
            return {
                ...state,
                workOrderList: [...action.Payload.data],
                totalCount: action.Payload.totalCount
            }
        case VIEW_WORKORDER_LIST:
            return { ...state, ...action.Payload }
        case WORKORDER_DETAILS:
            return { ...state, workorderDetails: { ...action.Payload } }
        case PRODUCT_DETAILS_WITH_VENDOR:
            return { ...state, selectedProduct: { ...action.Payload } }
        case BACKLOG_LIST:
            return { ...state, backlogList: [...action.Payload] }
        case LOCATION_DEALER_DETAILS_OF_WORKORDER:
            return { ...state, workorderInitialDetails: { ...action.Payload } }
        case RECENT_ORDERS:
            return { ...state, recentOrders: [...action.Payload] }
        case DASBOARD_WORKORDER_LIST:
            return { ...state, dashboardWorkOrderList: [...action.Payload] }
        case RESET_PICKER_STATE:
            return { ...state, resetstate: action.Payload }
        default:
            return state;
    }
};

export default workorderReducer;