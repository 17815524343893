import { FETCH_SAFETY_POLICY_DATA } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getSafetyPolicy: [] };

const safetyPolicyReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SAFETY_POLICY_DATA:
            return { ...state, getSafetyPolicy: [...action.Payload] }
        default:
            return state;
    }
};

export default safetyPolicyReducer;