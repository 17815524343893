import {
    LOAD_SCHEDULER,
    LOAD_POSITIONS,
    LOAD_EMPLOYEES,
    UPDATE_SCHEDULE
} from '../../constants/Actiontypes'

const initialState = {
    employeeList: [],
    positionsList: [],
    schedulerItems: {
        resources: [],
        events: [],
        totalSummary:[]
    }
};

const schedulerReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SCHEDULER:
            return {
                ...state,
                schedulerItems: { ...action.Payload.data }
            }

        case LOAD_POSITIONS:
            return {
                ...state,
                positionsList: [...action.Payload]
            }

        case LOAD_EMPLOYEES:
            return {
                ...state,
                employeeList: [...action.Payload]
            }

        case UPDATE_SCHEDULE:
            return {
                ...state,
                schedulerItems: [...action.Payload]
            }

        default:
            return state;
    }
};

export default schedulerReducer;