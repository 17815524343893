import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './redux/reducers';
const loggerMiddleware = createLogger();
const middlewares = [];
let store = null;
/**
        * <h5>Summary of store.js: </h5>
        * This models creates store with main reducer and holds the whole state tree of this application. The only way to change the state inside it is to dispatch an action on it.
        * <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
        * <h6>Last updated by: <small>30/09/2019</small> </h6>
        * <h6>Reason for modify: <small>To create doc for Store module </small> </h6>
*/
if (process.env.NODE_ENV === `development`) {
  store = createStore(
    rootReducer,
    applyMiddleware(
      thunkMiddleware,
      // loggerMiddleware,
    ),
  );
} else {
  store = createStore(
    rootReducer,
    applyMiddleware(
      thunkMiddleware,
    ),
  );
}

export default store;