import axios from '../../helpers/axiosInstance';
import * as axoiss from 'axios';
import moment from 'moment';
import date_formate from '../../helpers/dateInstance'
import authGuard from '../../helpers/authGuard';

import {
    LOCATION_CHANGE
} from '../../constants/Actiontypes';

const AuthService = new authGuard();
export const loginSuccess = () => ({
    type: 'AUTH_SUCCESS',
})

export const loginFailure = (data) => ({
    type: 'AUTH_FAIL',
    data
})

export const logoutAction = () => ({
    type: 'AUTH_LOGOUT'
})

const requestProfile = () => ({
    type: 'AUTH_REQUEST_PROFILE',
})

export const receiveProfile = (data) => ({
    type: 'AUTH_RECEIVE_PROFILE',
    data
});

export const setPathChange = async (history = false, optionalParams = false, crrtState = false) => {
    const formateDataPrev = (_histry) => {
        return {
            pathname: _histry.location.pathname,
            state: _histry.location.state ? { ..._histry.location.state } : {},
            optionalParams: optionalParams ? { ...optionalParams } : {}
        }
    };

    const formateDataCur = (data) => {
        return {
            pathname: data.pathname,
            state: data.state ? { ...data.state } : {},
            optionalParams: data.optionalParams ? { ...data.optionalParams } : {}
        }
    };

    const locationStatus = {
        previousState: history ? formateDataPrev(history) : {
            pathname: '/',
            state: {},
            optionalParams: {}
        },
        currentState: crrtState ? formateDataCur(crrtState) : {
            pathname: '/',
            state: {},
            optionalParams: {}
        }
    };

    await localStorage.setItem('@currentState', JSON.stringify(locationStatus));
    if (history) {
        history.push(crrtState);
    }
};

export const getCurrentState = async () => {
    const storageData = await localStorage.getItem('@currentState');
    if (storageData) {
        return JSON.parse(storageData);
    }

    return false;
};


export const AfterLoginAuth = (data, history) => dispatch => {
    dispatch(loginSuccess())
    dispatch(receiveProfile(data.data));
    if (AuthService.setToken(data.data)) {
        //console.log("RoleID::: ",data.data.roleid)
        if (data.data.roleid === 1) {
            history.push({
                pathname: '/serviceCapture'
            });
        } else if (data.data.roleid === 12) {
            history.push({
                pathname: '/lead'
            });
        } else {
            history.push({
                pathname: '/dashboard'
            });
        }
    }
}

export const TokenRefresh = (data) => async dispatch => {
    const response = await axios.post('/api/users/login', data)
    if (response.data.success) {
        dispatch(loginSuccess())
        dispatch(receiveProfile(response.data));
        response.data.sessionTimeOut = moment(new Date()).add(30, 'm').toDate();
        sessionStorage.setItem('UserData', JSON.stringify(response.data));
    }
}

export const verifyToken = (data, history) => async dispatch => {
    try {
        const response = await axios.post('/api/users/login', data)
        if (response.data.success) {
            dispatch(loginSuccess())
            dispatch(receiveProfile(response.data));
            response.data.sessionTimeOut = moment(new Date()).add(30, 'm').toDate();
            sessionStorage.setItem('UserData', JSON.stringify(response.data));
        } else if (response.data.hasOwnProperty('data') && response.data.data == "noaccess") {
            history.push('/noaccess');
            return response;
        } else {
            history.push('/login');
            return response;
        }
    } catch (e) {
        dispatch(loginFailure(e.message))
        history.push('/login');
    }
}

export const login_action_log = (data, type, isNotLogin) => async dispatch => {
    const { platform, appName, userAgent, language, connection } = window.navigator;
    let desc = `Network -${connection ? connection.effectiveType : "Couldnt get"}, Lang -${language} - Username: ${data.uname} pass: ${data.pass}`;
    let logParam = {};
    let url = '';
    if (isNotLogin) {
        if (localStorage.getItem('loggedInID') != 'undefined') {
            url = '/api/users/log_session/' + localStorage.getItem('loggedInID');
        }
    } else {
        let ip = await axoiss.get('https://api.ipify.org/?format=json');
        logParam.UserID = data ? data.userid ? data.userid : null : null;
        logParam.LoggedLocation = data.cords;
        logParam.DeviceType = platform;
        logParam.LoginResult = type;
        logParam.UserAgent = userAgent;
        logParam.Browser = desc;
        logParam.LoginFailedReson = data.message;
        logParam.SourceIP = ip ? ip.data ? ip.data.ip : '' : '';
        url = '/api/users/log_session';
    }
    try {
        if (url != '') {
            const response = await axios.post(url, logParam);
            if (type === 'SUCCESS') {
                localStorage.setItem('loggedInID', JSON.stringify(response.data.data[0]));
            }
        }
        return true;
    } catch (e) {
        return false;
    }
}
