import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

import { Route, Switch, BrowserRouter } from "react-router-dom";

import {
  // unregister,
  register
} from './registerServiceWorker';

import indexRoutes from "./routes/index.jsx";
import store from './store.js';
import { Provider } from 'react-redux';
import { create } from "jss";

import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/responsive.scss";
import "./assets/css/common_style.scss";
import "./assets/css/responsive-after-update.scss";
import "./assets/css/demo.css";
import "./assets/sass/light-bootstrap-dashboard.css";
import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/bootstrap_table_custom_style.css';
import 'react-tagsinput/react-tagsinput.css'
import 'survey-react/survey.css';
import ErrorBoundary from './helpers/reusable/ErrorBoundary'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Autocomplete from 'react-autocomplete'

/**
 * Summary of Index.js
 * Imports component that is being used while application loads (Initial Load )
 * <ol>
 *      <li> ErrorBoundary, Route  </li>
 *      <li> ErrorBoundary : imported to  capture all errors (crashes)  </li>
 *      <li> Route : List all routes that should be loaded initially while application get loaded  </li>
 * </ol>
 *  <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
 *  <h6>Last updated by: <small>30/09/2019</small> </h6>
 *  <h6>Reason for modify: <small>To create doc for Index.js </small> </h6>
 */
ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return <Route path={prop.path} component={prop.component} name={prop.name} exact={prop.exact} key={key} />;
          })}
        </Switch>
      </BrowserRouter>
    </ErrorBoundary>

  </Provider>,
  document.getElementById("root")
);

// Do this once
// unregister();
// register();


