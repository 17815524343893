import { FETCH_EMPWAGES_LIST, GET_EMPWAGES_BY_ID } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getEmpWagesList: [] , getEmpWagesById: []};

const empWagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EMPWAGES_LIST:
            return { ...state, getEmpWagesList: [...action.Payload] }
        case GET_EMPWAGES_BY_ID:
            return {...state,getEmpWagesById:[...action.Payload]}
        default:
            return state;
    }
};

export default empWagesReducer;