import { GET_ACCIDENTALREPORT_BY_ID, FETCH_ACCIDENTALREPORT_LIST } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getaccidentalReportList: [] , getaccidentalReportById: []};

const accidentalReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACCIDENTALREPORT_LIST:
            return { ...state, getaccidentalReportList: [...action.Payload] }
        case GET_ACCIDENTALREPORT_BY_ID:
            return {...state,getaccidentalReportById:[...action.Payload]}
        default:
            return state;
    }
};

export default accidentalReportReducer;