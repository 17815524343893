import {
    GET_ALL_FILTER_LIST
    , LOAD_ALL_SERVICE_DATA_LIST
} from '../../constants/Actiontypes';

import { reducer } from 'redux-form';

const initialState = {
    area_manager_list: [],
    director_operation_list: [],
    service_data_list: [],
};

const serviceDataReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_FILTER_LIST:
            return {
                ...state,
                director_operation_list: [...action.Payload.data],
                area_manager_list: [...action.Payload.AM_list]
            }

        case LOAD_ALL_SERVICE_DATA_LIST:
            return {
                ...state,
                service_data_list: [...action.Payload]
            }

        default:
            return state;
    }
};

export default serviceDataReducer;