import { FETCH_POSITION_LIST, GET_POSITION_BY_ID } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getPositionList: [] , getPositionById: []};

const positionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POSITION_LIST:
            return { ...state, getPositionList: [...action.Payload] }
        case GET_POSITION_BY_ID:
            return {...state,getPositionById:[...action.Payload]}
        default:
            return state;
    }
};

export default positionReducer;