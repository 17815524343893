import loadable from '@loadable/component'
import authGuard from '../helpers/authGuard';
import DocuSignHome from '../components/DocuSign/docuSignHome';
import DocuSignRedirect from '../components/DocuSign/docuSignRedirect';
const AsynLogin = loadable(() => import("../components/logincomponent/login"));
const checkInPageComponent = loadable(() => import("../components/CheckIn_CheckOut/checkIn"));
const registerNewUserPageComponent = loadable(() => import("../components/CheckIn_CheckOut/registerNewUser"));
const ApplicaitonLandingPage = loadable(() => import("../components/MainBoard/applicaitonLandingPage"));
const LoginValidate = loadable(() => import("./InitComponent"));

/**
 *  Summary of Route Index
 *  Imports Routes that is being used while application loads (Initial Load )
 *  <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
 *  <h6>Last updated by: <small>30/09/2019</small> </h6>
 *  <h6>Reason for modify: <small>To create doc for Route Index </small> </h6>
 */

const AsynSurvey = loadable(() => import("../components/Survey/TakeSurvey"));
const AsynDashboardlayout = loadable(() => import("../layouts/Dashboard/Dashboard.jsx"));
const AuthService = new authGuard();
AuthService.validateToken();

var indexRoutes = [
    { path: "/login", name: "Login", exact: true, component: AsynLogin },
    { path: "/checkIn", name: "checkIn", exact: true, component: checkInPageComponent },
    { path: "/registerNewUser", name: "registerNewUser", exact: true, component: registerNewUserPageComponent },
    { path: "/landingPage", name: "Landing", exact: true, component: ApplicaitonLandingPage },
    { path: "/init", name: "LoginValidate", exact: true, component: LoginValidate },
    { path: "/docuSign", name: "DocuHome", exact: true, component: DocuSignHome },
    { path: "/docuSign/:token", name: "DocuSignAuthHome", exact: false, component: DocuSignHome },
    { path: "/docusign-redirect", name: "DocuRedirectHome", exact: true, component: DocuSignRedirect },
    { path: "/docusign-redirect/:param", name: "DocuRedirect", exact: true, component: DocuSignRedirect },
    { path: "/take_survey/:reponseid", name: "Survey", exact: true, component: AsynSurvey },
    { path: "/", name: "Home", component: AsynDashboardlayout },
];


export default indexRoutes;
