import {
    INVOICE_LIST,
    VIN_BD_INVOICE_LIST,
    VIN_DUPLICATE_INVOICE_LIST,
    VIN_DECODE_LIST
} from '../../constants/Actiontypes';

const initialState = {
    invoiceList: [],
    getInvoiceList: [],
    getDuplicateInvoiceList: [],
    getDecodeList: [],
    totalCount: 0
};

const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVOICE_LIST:
            return {
                ...state,
                invoiceList: [...action.Payload.data],
                totalCount: action.Payload.totalCount
            }
        case VIN_BD_INVOICE_LIST:
            return { ...state, getInvoiceList: [...action.Payload] }
        case VIN_DUPLICATE_INVOICE_LIST:
            return { ...state, getDuplicateInvoiceList: [...action.Payload] }
        case VIN_DECODE_LIST:
            return { ...state, getDecodeList: [...action.Payload] }
        default:
            return state;
    }
};

export default invoiceReducer;