import React, {Component} from 'react';
import axios from '../../helpers/axiosInstance';
import { Redirect } from "react-router-dom";
import StackTrace from "stacktrace-js";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
      this.getBack = this.getBack.bind(this);
     
    }
  
     /**
         * <h5>Summary of getDerivedStateFromError: </h5>
         * this function sets "hasError" to true only if application crashed or if ErrorBoundary receives error 
         * <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
         * <h6>Last updated by: <small>30/09/2019</small> </h6>
         * <h6>Reason for modify: <small>To create doc for Error Boundary </small> </h6>
    */
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  

     /**
         * <h5>Summary of componentDidCatch: </h5>
         * This function gets triggered only when component "ErrorBoundary" receives any error 
         * <ol>
         *  <li> This function uses  StackTrace to get detailed depth of the error and store it in "_details" variables</li>
         *  <li> Once error is stored in the "_details" variable and set it to "error api "</li>
         * </ol>
         * <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
         * <h6>Last updated by: <small>30/09/2019</small> </h6>
         * <h6>Reason for modify: <small>To create doc for Error Boundary </small> </h6>
    */
    componentDidCatch = async(error, errorInfo)=> {
      let _details = '';

      await StackTrace.fromError(error).then(err => {
        _details = err;
      });

      let _error_details = {
        error : _details,
        error_information : errorInfo,
      }
      const updateStatus = await axios.post('/api/error/error', _error_details);
    }
   

     /**
     * <h5>Summary of getBack: </h5>
     * Navigating back to dashbaord page.</br>
     * 
     * <h6>Last Modified by: <small>Premkumar</small> </h6>
     * <h6>Last updated on: <small>26/09/2019</small> </h6>
     * <h6>Reason for modify: <small>To create doc for Error Boundary</small> </h6>
     */
    getBack = async() =>{
      window.location.href = '/dashbaord';
    }
  
    render() {
      if (this.state.hasError) {
        return <div className="text-center">
            <h1 className="text-center">Something went wrong.</h1>
            <a href='#' onClick={this.getBack} className="btn btn-primary">Try Again </a>
        </div>
      }
     
      return this.props.children; 
    }
  }

  export default ErrorBoundary;