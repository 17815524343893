const initialState = {
    isAuthenticated: false,
    userPortal: false,
    isFailure: false,
    isLoading: true,
    current_user: null,
    failurereason: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LAYOUT_PORTAL':
            return {
                ...state,
                userPortal: action.data || false,
            }

        case 'AUTH_SUCCESS':
            return {
                ...state,
                isAuthenticated: true,
                isFailure: false,
                isLoading: false
            }

        case 'AUTH_FAIL':
            return {
                ...state,
                isAuthenticated: false,
                userPortal: false,
                isFailure: true,
                failurereason: action.data,
                isLoading: false

            }

        case 'AUTH_LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                userPortal: false,
                isFailure: false,
                isLoading: false,
                current_user: null,
            }

        case 'AUTH_LOGOUT_FAIL':
            return {
                ...state,
                isAuthenticated: false,
                userPortal: false,
                isFailure: true,
                isLoading: false,
                current_user: null,
            }

        case 'AUTH_REQUEST_PROFILE':
            return {
                ...state,
                isFailure: false,
                userPortal: false,
                isLoading: true,
                current_user: null,
            }

        case 'AUTH_RECEIVE_PROFILE':
            return {
                ...state,
                isLoading: false,
                isFailure: false,
                current_user: action.data
            }

        default:
            return state;
    }

}


export default authReducer