import {
    REPORT_DEALERSHIP,
    REPORT_WORKORDERLIST,
    REPORT_CHEMICALCOST,
    REPORT_PURCHASEORDER,
    LOAD_EMPLOYEE_WAGES_REPORT,
    REPORT_SUPPLYITEM,
    LOAD_EMPLOYEE_WAGES_WEEKLY_REPORT,
    LOAD_FLOATER_REPORT,
    OVERALL_SUPPLY_ITEM_REPORT
} from '../../constants/Actiontypes'

const initialState = {
    reportWorkOrderList: [],
    reportDealership: [],
    reportChemicalCost: [],
    reportPurchaseOrder: [],
    reportSupplyItem: [],
    overAllSupplyItemReport: [],
    dailyLaborReport: [],
    weeklyLaborReport: [],
    floaterReport: [],
};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_DEALERSHIP:
            return {
                ...state,
                ...action.Payload
            }

        case REPORT_WORKORDERLIST:
            return {
                ...state,
                reportWorkOrderList: [...action.Payload]
            }

        case REPORT_CHEMICALCOST:
            return {
                ...state,
                reportChemicalCost: [...action.Payload]
            }

        case REPORT_PURCHASEORDER:
            return {
                ...state,
                reportPurchaseOrder: [...action.Payload]
            }

        case REPORT_SUPPLYITEM:
            return {
                ...state,
                reportSupplyItem: [...action.Payload]
            }

        case OVERALL_SUPPLY_ITEM_REPORT:
            return {
                ...state,
                overAllSupplyItemReport: [...action.Payload]
            }

        case LOAD_EMPLOYEE_WAGES_REPORT:
            return {
                ...state,
                dailyLaborReport: [...action.Payload.report]
            }

        case LOAD_EMPLOYEE_WAGES_WEEKLY_REPORT:
            return {
                ...state,
                weeklyLaborReport: [...action.Payload.report]
            }

        case LOAD_FLOATER_REPORT:
            return {
                ...state,
                floaterReport: [...action.Payload.report]
            }

        default:
            return state;
    }
}

export default reportsReducer;
