import moment from 'moment-timezone';
/**
     * <h5>Summary of Date Formate: </h5>
     * This function formates date based on the data provided
     * <ol>
     *      <li> Date and formate will be sent from parent function </li>
     * </ol>
     * <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
     * <h6>Last updated by: <small>30/09/2019</small> </h6>
     * <h6>Reason for modify: <small>To create doc for Custom Model </small> </h6>
*/
let date_formate = {
    dateFormate: (date = '', formate = '') => {
        if (date && formate) {
            return moment(date).utcOffset("+05:00").format(formate);
        }
        else if (date) {
            return moment(date).utcOffset("+05:00").format('MMM-DD-YYYY')
        }
        else {
            return '';
        }
    },

    dateInstance: () => {
        return moment()
    }
}

export default date_formate;
