import { FETCH_LEADACTIVITY_LIST, GET_LEADACTIVITY_BY_ID, GET_SALES_REPRESENTATIVE, GET_LEAD_REPORT, FETCH_ACTIVITY_LIST, GET_LEAD_ACTIVITY_REPORT } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = {
    getLeadList: [],
    getLeadById: [],
    getSalesRepresentative: [],
    getLeadReport: [],
    getActivityList: [],
    leadActivity: [],
};

const leadReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LEADACTIVITY_LIST:
            return { ...state, getLeadList: [...action.Payload] }
        case GET_SALES_REPRESENTATIVE:
            return { ...state, getSalesRepresentative: [...action.Payload] }
        case GET_LEADACTIVITY_BY_ID:
            return { ...state, getLeadById: [...action.Payload] }
        case GET_LEAD_REPORT:
            return { ...state, getLeadReport: [...action.Payload] }
        case FETCH_ACTIVITY_LIST:
            return { ...state, getActivityList: [...action.Payload] }
        case GET_LEAD_ACTIVITY_REPORT:
            return { ...state, leadActivity: [...action.Payload] }
        default:
            return state;
    }
};

export default leadReducer;