/**
     * <h5>Summary of registerServiceWorker: </h5>
     * In production, we register a service worker to serve assets from local cache.

     * File has beed updated for the WC - list issue 08/06/2020
*/

export function register() {

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      navigator.serviceWorker
        .register(swUrl, {})
        .then(registration => {
          console.log('registration State Changed', registration);
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            installingWorker.onstatechange = () => {
              console.log('installingWorker State Changed', installingWorker);
              console.log('installingWorker State', installingWorker.state);
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // At this point, the old content will have been purged and
                  // the fresh content will have been added to the 
                  // It's the perfect time to display a "New content 
                  // available; please refresh." message in your web app.
                  console.log('New content is available; please refresh.');
                  alert("New Changes Available. Please Close the current window and Re-open it!");
                }
                if (installingWorker.state === 'waiting') {
                  // This assumes there's a button with id='skip-waiting-button' that
                  // users should click to get the new SW to activate immediately.
                  setTimeout(() => {
                    installingWorker.postMessage('skipWaiting');
                  }, 1000);
                  // Assume that 'display' is 'none' initially.
                } else {
                  // At this point, everything has been precached.
                  // It's the perfect time to display a
                  // "Content is cached for offline use." message.
                  console.log('Content is cached for offline use.');
                  unregister();
                }
              }
              if (installingWorker.state === 'waiting') {
                // This assumes there's a button with id='skip-waiting-button' that
                // users should click to get the new SW to activate immediately.
                setTimeout(() => {
                  installingWorker.postMessage('skipWaiting');
                }, 1000);
                // Assume that 'display' is 'none' initially.
              }
            };
          };
        })
        .catch(error => {
          console.error('Error during service worker registration:', error);
        });

        if(ServiceWorkerRegistration) {
          ServiceWorkerRegistration.onupdatefound = function () {
            console.log('update found')
          };
        }
    });
  }
}

// In your service worker:
window.self.addEventListener('message', event => {
  if (event.data === 'skipWaiting') {
    window.self.skipWaiting();
  }
});

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}