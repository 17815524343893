import { FETCH_EMP_LIST, GET_EMP_BY_ID, LOAD_PRODUCTIVITY_LIST } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getEmployeeList: { claimEmployeeData: [], hospitalData: [] }, getEmployeeById: [] };

const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EMP_LIST:
            return { ...state, getEmployeeList: { ...action.Payload } }

        case GET_EMP_BY_ID:
            return { ...state, getEmployeeById: [...action.Payload] }

        case LOAD_PRODUCTIVITY_LIST:
            return { ...state, productivityReportList: [...action.Payload] }

        default:
            return state;
    }
};

export default employeeReducer;