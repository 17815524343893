import {
    LOAD_QUICKBOOK_ACCOUNTS_LIST
    , LOAD_QUICKBOOK_ITEMS_LIST
    , LOAD_DUBLICATE_VIN_COMMENTS_LIST
} from '../../constants/Actiontypes';

const initialState = {
    quickbook_accounts: [],
    quickbook_items: [],
    vin_duplicate_comments_list: [],
};

const mastersReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_QUICKBOOK_ACCOUNTS_LIST:
            return {
                ...state,
                quickbook_accounts: [...action.Payload.data]
            }

        case LOAD_QUICKBOOK_ITEMS_LIST:
            return {
                ...state,
                quickbook_items: [...action.Payload.data]
            }

        case LOAD_DUBLICATE_VIN_COMMENTS_LIST:
            return {
                ...state,
                vin_duplicate_comments_list: [...action.Payload.data]
            }

        default:
            return state;
    }
};

export default mastersReducer;