import {
    DEALEARGET
    , LOCATION
    , CUSTOMER
    , GETONEDEALER
    , LOCATION_SEARCH
    , CUSTOMER_SEARCH
    , GETDEALER_DROPDOWN
    , FETCH_DEALER_DETAILS
    , LOAD_ALL_DEALER_SERVICES
    , LOAD_DEALER_SERVICE_LOCATION
    , CLEAR_DEALER_SERVICES_AND_LOCATION
    , LOAD_ALL_QUICKBOOK_SERVICES
    , GET_EMP_DROPDOWN
    , GET_POSITION_DROPDOWN
    , GET_POSITION_DROPDOWN_BY_ID
    , GET_EMP_DROPDOWN_BY_ID
    ,GET_EMP_DROPDOWN_BY_DEALER_ID
    , GET_VIEW_DEVICES
    , GET_DEALER_DROPDOWN_BY_EMP_ID
    , GET_LOCATION_DROPDOWN
} from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = {
    getAllDealer: [],
    location: [],
    customer: [],
    getOneDealer: [],
    locationSearch: [],
    customerSearch: [],
    getDealerDropdown: [],
    getEmployeeDropdown: [],
    getPositionDropdown: [],
    getLocationDropdown: [],
    getPositionDropdownById: [],
    getEmpDropdownById: [],
    getEmpDropdownByDealerId: [],
    getDealerDetails: [],
    quickBookServices: [],
    dealerServices: {
        selectedLoaction: { label: "Allen Samuels Dodge", value: 10001 },
        list: []
    },
    viewDevices: [],
    getDealerDropdownByEmpId: []
};
const dealerReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEALEARGET:
            return { ...state, getAllDealer: [...action.Payload] }
        case LOCATION:
            return { ...state, location: [...action.Payload] }
        case CUSTOMER:
            return { ...state, customer: [...action.Payload.data] }
        case GETONEDEALER:
            return { ...state, getOneDealer: [...action.Payload] }
        case GET_VIEW_DEVICES:
            return { ...state, viewDevices: [...action.Payload] }
        case LOCATION_SEARCH:
            return { ...state, locationSearch: [...action.Payload] }
        case CUSTOMER_SEARCH:
            return { ...state, customerSearch: [...action.Payload] }
        case GETDEALER_DROPDOWN:
            let delaer = {};
            if (action.Payload && action.Payload.length > 0) {
                delaer = { label: action.Payload[0].DealerName, value: action.Payload[0].DealerID };
            }
            return {
                ...state,
                getDealerDropdown: [...action.Payload],
                dealerServices: {
                    ...state.dealerServices,
                    selectedLoaction: delaer
                }
            }
        case GET_EMP_DROPDOWN:
            return { ...state, getEmployeeDropdown: [...action.Payload] }

        case GET_POSITION_DROPDOWN:
            return { ...state, getPositionDropdown: [...action.Payload] }

        case GET_LOCATION_DROPDOWN:
            return { ...state, getLocationDropdown: [...action.Payload] }

        case GET_POSITION_DROPDOWN_BY_ID:
            return { ...state, getPositionDropdownById: [...action.Payload] }

        case GET_EMP_DROPDOWN_BY_ID:
            return { ...state, getEmpDropdownById: [...action.Payload] }

        case GET_EMP_DROPDOWN_BY_DEALER_ID:
            return { ...state, getEmpDropdownByDealerId: [...action.Payload] }   
        
        case GET_DEALER_DROPDOWN_BY_EMP_ID:
            // console.log('Inside the REDUCER : get emp dropdown by dealer id : ', action.Payload)
            return { ...state, getDealerDropdownByEmpId: [...action.Payload] }   
            
        case LOAD_ALL_DEALER_SERVICES:
            return {
                ...state,
                dealerServices: {
                    ...state.dealerServices,
                    list: [...action.Payload]
                }
            }


        case LOAD_DEALER_SERVICE_LOCATION:
            return {
                ...state,
                dealerServices: {
                    ...state.dealerServices,
                    selectedLoaction: action.Payload
                }
            }

        case CLEAR_DEALER_SERVICES_AND_LOCATION:
            return {
                ...state,
                dealerServices: {
                    selectedLoaction: '',
                    list: []
                }
            }

        case LOAD_ALL_QUICKBOOK_SERVICES:
            return {
                ...state,
                quickBookServices: [...action.Payload]
            }

        case FETCH_DEALER_DETAILS:
            return { ...state, getDealerDetails: [...action.Payload] }
        default:
            return state;
    }
}

export default dealerReducer;
