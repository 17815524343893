/**
        * <h5>Summary of Actiontypes.js: </h5>
        * This module has all the actions (constants) used inside the application reducers.
        * <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
        * <h6>Last updated by: <small>30/09/2019</small> </h6>
        * <h6>Reason for modify: <small>To create doc for action types </small> </h6>
*/

export const LOCATION_CHANGE = 'LOCATION_CHANGE';

export const UPDATE = 'UPDATE';
export const DEALEARGET = 'DEALEARGET';
export const CATEGORY = 'CATEGORY';
export const VENDAR = 'VENDAR';
export const VENDARDATE = 'VENDARDATE';
export const PRODUCTS = 'PRODUCTS';
export const UNIT = 'UNIT';
export const LOCATION = 'LOCATION'
export const CUSTOMER = 'CUSTOMER'
export const VENDORPRICE = 'VENDORPRICE';
export const GETONEDEALER = 'GETONEDEALER'
export const CUSTOMER_SEARCH = 'CUSTOMER_SEARCH'
export const LOCATION_SEARCH = 'LOCATION_SEARCH'
export const LOCATION_LIST = 'LOCATION_LIST'
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH'
export const GETDEALER_DROPDOWN = 'GETDEALER_DROPDOWN'
export const PRODUCT_DETAILS = 'PRODUCT_DETAILS'
export const PRODUCT_PRICE = 'PRODUCT_PRICE'
export const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL'
export const RECENT_ORDERS = "RECENT_ORDERS"
export const SUPPLYITEM = 'SUPPLYITEM'
export const OVERALL_SUPPLY_ITEM_REPORT = 'OVERALL_SUPPLY_ITEM_REPORT';

export const LOAD_EMPLOYEE_WAGES_REPORT = 'LOAD_EMPLOYEE_WAGES_REPORT';
export const LOAD_EMPLOYEE_WAGES_WEEKLY_REPORT = 'LOAD_EMPLOYEE_WAGES_WEEKLY_REPORT';
export const LOAD_FLOATER_REPORT = 'LOAD_FLOATER_REPORT';


export const LOAD_ALL_DEALER_SERVICES = 'LOAD_ALL_DEALER_SERVICES'
export const LOAD_DEALER_SERVICE_LOCATION = 'LOAD_DEALER_SERVICE_LOCATION'
export const CLEAR_DEALER_SERVICES_AND_LOCATION = 'CLEAR_DEALER_SERVICES_AND_LOCATION'
export const LOAD_ALL_QUICKBOOK_SERVICES = 'LOAD_ALL_QUICKBOOK_SERVICES'


export const CREW_LEADER_LIST = 'CREW_LEADER_LIST'
export const DEALER_BY_LOCATION = 'DEALER_BY_LOCATION';
export const WORKORDER_LIST = 'WORKORDER_LIST';
export const VIEW_WORKORDER_LIST = 'VIEW_WORKORDER_LIST';
export const WORKORDER_DETAILS = 'WORKORDER_DETAILS';
export const PRODUCT_DETAILS_WITH_VENDOR = 'PRODUCT_DETAILS_WITH_VENDOR';
export const DASBOARD_WORKORDER_LIST = 'DASBOARD_WORKORDER_LIST';
export const BACKLOG_LIST = 'BACKLOG_LIST';
export const LOCATION_DEALER_DETAILS_OF_WORKORDER = 'LOCATION_DEALER_DETAILS_OF_WORKORDER';
export const RESET_PICKER_STATE = 'RESET_PICKER_STATE'


export const GETALLVENDOR = 'GETALLVENDOR';
export const GETONEVENDOR = 'GETONEVENDOR';
export const DELETEVENDOR = 'DELETEVENDOR';
export const GETVENDOR_DROPDOWN = 'GETVENDOR_DROPDOWN'


export const SURVEY_LIST = 'SURVEY_LIST';
export const SURVEY_ADD = 'SURVEY_ADD';
export const GET_ONE_SURVEY = 'GET_ONE_SURVEY';
export const SURVEY_DELETE = 'GETALLVENDOR';
export const USER_LIST = 'USER_LIST';
export const SURVEY_EMAIL_TEMPLATES = 'SURVEY_EMAIL_TEMPLATES';
export const TAKE_SURVEY = 'TAKE_SURVEY';
export const SURVEY_REPORT = 'SURVEY_REPORT';

export const LOAD_ALL_SERVICE_CAPTURE_LIST = 'LOAD_ALL_SERVICE_CAPTURE_LIST'
export const LOAD_DUBLICATE_VIN_COMMENTS = 'LOAD_DUBLICATE_VIN_COMMENTS'
export const PERTICULAR_DEALER_SERVICES = 'PERTICULAR_DEALER_SERVICES'
export const LOAD_COMPLETED_INVOICE_BY_ID = 'LOAD_COMPLETED_INVOICE_BY_ID'
export const LOAD_COMPLETED_INVOICE_LIST = 'LOAD_COMPLETED_INVOICE_LIST'

export const STATUSGET_DROPDOWN = 'STATUSGET_DROPDOWN';
export const STATUS_PURCHASEORDER = 'STATUS_PURCHASEORDER';
export const STATUS_CLAIMPORTAL = 'STATUS_CLAIMPORTAL';

export const LOAD_QUICKBOOK_ACCOUNTS_LIST = 'LOAD_QUICKBOOK_ACCOUNTS_LIST';
export const LOAD_QUICKBOOK_ITEMS_LIST = 'LOAD_QUICKBOOK_ITEMS_LIST';
export const LOAD_DUBLICATE_VIN_COMMENTS_LIST = 'LOAD_DUBLICATE_VIN_COMMENTS_LIST';

export const GET_ALL_FILTER_LIST = 'GET_ALL_FILTER_LIST';
export const LOAD_ALL_SERVICE_DATA_LIST = 'LOAD_ALL_SERVICE_DATA_LIST';


export const FETCH_INCIDENT_LIST = "FETCH_INCIDENT_LIST";
export const FETCH_SINGLE_INCIDENT = "FETCH_SINGLE_INCIDENT";

export const FETCH_ACCIDENT_LIST = "FETCH_ACCIDENT_LIST";
export const FETCH_ACCIDENT = "FETCH_ACCIDENT";

export const FETCH_ERRORS = "FETCH_ERRORS";

export const DASHBOARD_COUNT = 'DASHBOARD_COUNT';
export const DASHBOARD_WORKORDER_LIST = 'DASHBOARD_WORKORDER_LIST';
export const DASBOARD_BACKLOG_LIST = 'DASBOARD_BACKLOG_LIST';
export const DASHBOARD_CHART = 'DASHBOARD_CHART';

export const REPORT_DEALERSHIP = 'REPORT_DEALERSHIP';
export const REPORT_WORKORDERLIST = 'REPORT_WORKORDERLIST';
export const REPORT_CHEMICALCOST = 'REPORT_CHEMICALCOST';
export const REPORT_PURCHASEORDER = 'REPORT_PURCHASEORDER';
export const REPORT_SUPPLYITEM = 'REPORT_SUPPLYITEM';

export const LOAD_SCHEDULER = 'LOAD_SCHEDULER';
export const LOAD_POSITIONS = 'LOAD_POSITIONS';
export const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';

export const FETCH_CHECKED_VENDOR_LIST = 'FETCH_CHECKED_VENDOR_LIST'
export const FETCH_DUP_CHECKED_VENDOR_LIST = 'FETCH_DUP_CHECKED_VENDOR_LIST'
export const FETCH_CHECKED_VENDOR_LIST_ALL = 'FETCH_CHECKED_VENDOR_LIST_ALL'
export const GET_VENDOR_LIST_DROPDOEN = 'GET_VENDOR_LIST_DROPDOEN'
export const GET_VENDOR_BY_ID = 'GET_VENDOR_BY_ID'
export const GET_VENDOR_FOR_EXPORT = 'GET_VENDOR_FOR_EXPORT'


export const GET_SERVICE_VENDORS = 'GET_SERVICE_VENDORS';
export const GET_SERVICE_LIST = 'GET_SERVICE_LIST';
export const GET_SERVICE_LIST_BY_ID = 'GET_SERVICE_LIST_BY_ID';
export const GET_SERVICE_LIST_ADMIN = 'GET_SERVICE_LIST_ADMIN';
export const GET_EXPORT_BY_ID = 'GET_EXPORT_BY_ID';
export const GET_USER_LIST = 'GET_USER_LIST';


export const WORKERS_LIST = 'WORKERS_LIST'
export const PAYROLL_LIST = 'PAYROLL_LIST'
export const SINGLE_WORKERS = 'SINGLE_WORKERS'


export const PURCHASEORDER_LIST = 'PURCHASEORDER_LIST'
export const GENERATE_PO_DETAILS = 'GENERATE_PO_DETAILS'
export const PURCHASEORDER_VIEW = 'PURCHASEORDER_VIEW'
export const RECEIVED_ITEMS = 'RECEIVED_ITEMS'
export const VERIFY_AND_COMPLETE = 'VERIFY_AND_COMPLETE'
export const RECENT_PO = 'RECENT_PO'
export const DELETE_PO = 'DELETE_PO'
export const PO_TOTAL_COUNT = 'PO_TOTAL_COUNT'

export const CREATE_ACCIDENT_REPORT = 'CREATE_ACCIDENT_REPORT'
export const FETCH_DEALER_DETAILS = 'FETCH_DEALER_DETAILS'

export const FETCH_HOSPITAL_LIST = 'FETCH_HOSPITAL_LIST'
export const GET_HOSPITAL_BY_ID = 'GET_HOSPITAL_BY_ID'

export const FETCH_EMPWAGES_LIST = 'FETCH_EMPWAGES_LIST'
export const GET_EMPWAGES_BY_ID = 'GET_EMPWAGESL_BY_ID'

export const FETCH_HOLIDAY_LIST = 'FETCH_HOLIDAY_LIST'
export const GET_HOLIDAY_BY_ID = 'GET_HOLIDAY_BY_ID'



export const FETCH_POSITION_LIST = 'FETCH_POSITION_LIST'
export const GET_POSITION_BY_ID = 'GET_POSITION_BY_ID'

export const FETCH_EMP_LIST = 'FETCH_EMP_LIST'
export const GET_EMP_BY_ID = 'GET_EMP_BY_ID'
export const LOAD_PRODUCTIVITY_LIST = 'LOAD_PRODUCTIVITY_LIST'

export const FETCH_SAFETY_POLICY_DATA = 'FETCH_SAFETY_POLICY_DATA'

export const PAYROLL_DEDUCTION = 'PAYROLL_DEDUCTION'


export const DASHBOARD_INCIDENT_LIST = 'DASHBOARD_INCIDENT_LIST'
export const DASHBOARD_ACCIDENT_LIST = 'DASHBOARD_ACCIDENT_LIST'
export const DASHBOARD_WORKERS_LIST = 'DASHBOARD_WORKERS_LIST'

export const STATUS_CLAIM_PORTAL = 'STATUS_CLAIM_PORTAL'
export const STATUS_WORKER_CLAIM_PORTAL = 'STATUS_WORKER_CLAIM_PORTAL'

export const VIN_DECODE = 'VIN_DECODE'
export const GET_ALL_ROLES = 'GET_ALL_ROLES'
export const GET_USER_DATA_ROLES = 'GET_USER_DATA_ROLES'
export const GET_USER_DATA_BY_ID = 'GET_USER_DATA_BY_ID'
export const GET_AREA_MANGER_DATA = 'GET_AREA_MANGER_DATA'
export const GET_AREA_MANAGER_LIST_WITH_LOCATION = 'GET_AREA_MANAGER_LIST_WITH_LOCATION'

export const INVOICE_LIST = 'INVOICE_LIST'

export const GET_EMP_DROPDOWN = 'GET_EMP_DROPDOWN'
export const GET_POSITION_DROPDOWN = 'GET_POSITION_DROPDOWN'
export const VIN_BD_INVOICE_LIST = 'VIN_BD_INVOICE_LIST'
export const VIN_DUPLICATE_INVOICE_LIST = 'VIN_DUPLICATE_INVOICE_LIST'
export const VIN_DECODE_LIST = 'VIN_DECODE_LIST'

export const GET_CLOCKAPPROVAL_BY_ID = 'GET_CLOCKAPPROVAL_BY_ID'
export const FETCH_CLOCKAPPROVAL_LIST = 'FETCH_CLOCKAPPROVA_LIST'

export const GET_ACCIDENTALREPORT_BY_ID = 'GET_ACCIDENTALREPORT_BY_ID'
export const FETCH_ACCIDENTALREPORT_LIST = 'FETCH_ACCIDENTALREPORT_LIST'

export const GET_VIEW_DEVICES = 'GET_VIEW_DEVICES'
export const GET_POSITION_DROPDOWN_BY_ID = 'GET_POSITION_DROPDOWN_BY_ID'
export const GET_EMP_DROPDOWN_BY_ID = 'GET_EMP_DROPDOWN_BY_ID'
export const GET_EMP_DROPDOWN_BY_DEALER_ID = 'GET_EMP_DROPDOWN_BY_DEALER_ID'
export const GET_DEALER_DROPDOWN_BY_EMP_ID = 'GET_DEALER_DROPDOWN_BY_EMP_ID'
export const GET_LOCATION_DROPDOWN = 'GET_LOCATION_DROPDOWN'

export const FETCH_LEADACTIVITY_LIST = 'FETCH_LEADACTIVITY_LIST'
export const GET_LEADACTIVITY_BY_ID = 'GET_LEADACTIVITY_BY_ID'
export const GET_SALES_REPRESENTATIVE = 'GET_SALES_REPRESENTATIVE'
export const GET_LEAD_REPORT = 'GET_LEAD_REPORT'
export const GET_LEAD_DASHBOARD_COUNT = 'GET_LEAD_DASHBOARD_COUNT'
export const FETCH_ACTIVITY_LIST = 'FETCH_ACTIVITY_LIST'
export const GET_LEAD_GRAPH_COUNT = 'GET_LEAD_GRAPH_COUNT'
export const GET_LEAD_COUNT_BY_SALES_REP = 'GET_LEAD_COUNT_BY_SALES_REP'
export const GET_LEAD_ACTIVITY_REPORT = 'GET_LEAD_ACTIVITY_REPORT'

export const GET_FORM_DIGITILIZATION_LIST = 'GET_FORM_DIGITILIZATION_LIST'
export const GET_INSURANCE_DETAILS =  'GET_INSURANCE_DETAILS'


export const GET_CARD_LIST = 'GET_CARD_LIST'
export const GET_CARD_LIST_By_ID = 'GET_CARD_LIST_By_ID'
export const GET_CARD_COMMENTS = 'GET_CARD_COMMENTS'
export const GET_CARD_DASHBOARD_COUNT = 'GET_CARD_DASHBOARD_COUNT'
export const GET_CARD_DASHBOARD_GRAPH_DATA = 'GET_CARD_DASHBOARD_GRAPH_DATA'


