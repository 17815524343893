import { STATUS_CLAIMPORTAL, FETCH_INCIDENT_LIST, FETCH_SINGLE_INCIDENT } from '../../constants/Actiontypes';



export const actions = {
    getIncidentList: (dispatch, incidents) => {
        dispatch({ type: FETCH_INCIDENT_LIST, incidents });
    },
    getIncident: (dispatch, incident) => {
        dispatch({ type: FETCH_SINGLE_INCIDENT, incident });
    },
    loadClaimPortal: (dispatch, data) => {
        dispatch({
            type: STATUS_CLAIMPORTAL,
            Payload: data
        });
    },
};

const initialState = {
    list: [],
    incident: {},
};

export const reducer = (state = initialState, action) => {
    // console.log(action.type, action)
    const { incidents, incident } = action;
    switch (action.type) {
        case FETCH_INCIDENT_LIST:
            return {
                ...state,
                list: incidents
            };
        case FETCH_SINGLE_INCIDENT:
            return {
                ...state,
                incident: incident
            };
        default:
            return state;
    }
};
