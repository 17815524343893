import React, { Component } from "react";
import { connect } from 'react-redux';
import { verifyToken, loginSuccess } from '../../redux/actions/user';
import axios from '../../helpers/axiosInstance';
import { Row, Col } from 'react-bootstrap';
import { Card, CardBody } from 'reactstrap';
const queryString = require('query-string');
const hasKey = 'Quadseal!123';
var jwt = require('jsonwebtoken');


class DocuSignHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlData: {},
      isAuthendicated: false,
      URL: '',
      urlInvalid: false,
      urlInvalidMessage: '',
    };
  }

  async componentDidMount() {

    try {

      const { match: { params } } = this.props;

      if (params.hasOwnProperty('token')) {
        let { token } = params;

        var decoded = false;
        try {
          token = token.replace(/{slash}/g, '/');
          var decodedHash = new Buffer(token, 'base64')
          var finalToken = decodedHash.toString();

          decoded = JSON.parse(finalToken);
        } catch (error) {
          console.log(error);
        }

        if (decoded && decoded.hasOwnProperty('id')) {
          const { url, id, isLead } = decoded;

          if (id) {
            let responce;
            if (isLead) {
              responce = await axios.get('api/docusign/getLeadDocuStatus/' + id);
            } else {
              responce = await axios.get('api/docusign/status/' + id);
            }

            if (responce && responce.data && responce.data.hasOwnProperty('success')) {
              if (responce.data.data.length > 0) {
                let data = responce.data.data[0];
                data = { ...data, isLead: !!isLead, Id: (!!isLead) ? data.id : data.Id };
                console.log(url, data.DocuStatus )
                await localStorage.setItem('@docuData', JSON.stringify(data));
                switch (data.DocuStatus) {
                  case "Sent":
                    this.setState({ URL: url, urlInvalid: false, isAuthendicated: true });
                    return;

                  case "Rejected":
                    this.setState({ isAuthendicated: false, urlInvalid: true, urlInvalidMessage: 'User has rejected this Document' });
                    return;

                  default:
                    this.setState({ isAuthendicated: false, urlInvalid: true, urlInvalidMessage: 'The requested document already completed' });
                    return;
                }
              } else this.setState({ isAuthendicated: false, urlInvalid: true, urlInvalidMessage: 'Token is Not Valid' });
            } else this.setState({ isAuthendicated: false, urlInvalid: true, urlInvalidMessage: 'Token is Not Valid' });
          }
        } else {
          this.setState({ isAuthendicated: false, urlInvalid: true, urlInvalidMessage: 'Token is Not Valid' });
        }
      }
    } catch (error) {
      console.log(error)
      this.setState({ isAuthendicated: false, urlInvalid: true, urlInvalidMessage: 'URL is Not Valid' });
    }
  };

  render() {
    const { isAuthendicated, urlInvalid, urlInvalidMessage, URL } = this.state;
    let Style = 'width:100%; height:96%; border:0; border-radius: 4px; overflow:hidden;',
      title = 'Plotly All Graph Types',
      allow = 'geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media',
      sandbox = 'allow-modals allow-forms allow-popups allow-scripts allow-same-origin';


    if (urlInvalid) {
      return (
        <div className="content docu-home-container">
          <div className="docu-header">Quad Seal Make Ready</div>
          <div className="flex-body">
            <div className="docu-home-body">
              <div className="head"><i className="fa fa-info-circle" aria-hidden="true"></i>Information</div>
              <div className="lbl">{urlInvalidMessage}</div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="content docu-home-container">
        <div className="docu-header">Quad Seal Make Ready</div>
        {
          isAuthendicated &&
          <Card>
            <CardBody>
              <Col md={12}>
                <div dangerouslySetInnerHTML={{ __html: `<iframe src="${URL}" title="${title}" allow="${allow}" style="${Style}" sandbox="${sandbox}"></iframe>` }} />
              </Col>
            </CardBody>
          </Card>
        }
        {
          isAuthendicated === false &&
          <div className="flex-body">
            <div className="docu-home-body">
              <div className="head">Validating your request</div>
              <div className="lbl">Please hold on a moment</div>
            </div>
          </div>
        }
      </div>
    );
  }
}




export default DocuSignHome;