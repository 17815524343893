import { GETALLVENDOR , DELETEVENDOR,VENDORPRICE,GETONEVENDOR , GETVENDOR_DROPDOWN} from '../../constants/Actiontypes';
const initialState = {getallvendor:[],deleteVendor:[],vendorprice:[],getOneVendor:[],getVendorDropdown:[]};
const vendorReducer = (state = initialState, action) => {
    switch (action.type) {  
    case GETALLVENDOR:
        return {...state,getallvendor:action.Payload}
    case DELETEVENDOR:
            return {...state,deleteVendor:action.Payload};
    case VENDORPRICE:
            return {...state,vendorprice:action.Payload};
    case GETONEVENDOR:
        return {...state,getOneVendor:action.Payload}
    case GETVENDOR_DROPDOWN:
        return {...state,getVendorDropdown:action.Payload}
    default:
        return state;
    }
};

export default vendorReducer;