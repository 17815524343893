import { WORKERS_LIST, PAYROLL_LIST, SINGLE_WORKERS, PAYROLL_DEDUCTION } from '../../constants/Actiontypes';

const initialState = { workersList: { claimEmployeeData: [], hospitalData: [] }, payrollList: [], workersEditForm: {}, payrollDeductionData: [] };

const worksClaimReducer = (state = initialState, action) => {
    switch (action.type) {
        case WORKERS_LIST:
            return {
                ...state,
                workersList: { ...action.Payload }
            }

        case SINGLE_WORKERS:
            return {
                ...state,
                workersEditForm: { ...action.Payload }
            }

        case PAYROLL_LIST:
            return {
                ...state,
                payrollList: [...action.Payload]
            }

        case PAYROLL_DEDUCTION:
            return {
                ...state,
                payrollDeductionData: [...action.Payload]
            }

        default:
            return state;
    }
};

export default worksClaimReducer;