import moment from 'moment';
import axios from './axiosInstance';
import Cookies from 'universal-cookie';	

import localStore from './localStore';
const StoreService = new localStore();
const cookies = new Cookies();	

 /**
         * <h5>Summary of authGuard: </h5>
         * this function handles user authentication
         * <ol>
         *      <li> loggedIn : This function triggers token function and returns token </li>
         *      <li> isTokenExpired : Checks token create time and token expiry time . Logouts user if the time is less than 0 else it wil refresh the token    </li>
         *      <li> refreshToken : Hits the api token and receives new token </li>
         *      <li> setToken: sets user token in local storage</li>
         *      <li> getToken : gets token form local storage</li>
         *      <li> validateToken : Hits "validateToken" api and check for token valid time. Based on response received it process the user request</li>
         *      <li> logout: Removes following objects 'id_token', 'token','window_time',loggedInID' from local storage</li>
         *      <li> getProfile : gets user details from local storage</li>
         * </ol>
         * <h6>Last Modified: <small>Dhileep Kumar</small> </h6>
         * <h6>Last updated by: <small>30/09/2019</small> </h6>
         * <h6>Reason for modify: <small>To create doc for Auth Guard </small> </h6>
*/

export default class authGuard {
    constructor() {
        this.getProfile = this.getProfile.bind(this)
    }

   


    loggedIn() {
        const token = this.getToken();
        return !!token
    }

    isTokenExpired(token) {
        if (this.getToken()) {
            var diff = moment.duration(moment(this.getToken().sessionTimeOut).diff(moment(new Date()))).asMinutes();
            if (diff < 0) {
                this.logout()
                window.location.href="/login";
                return false;
            }
            else
                if (diff < 11 && diff > 0) {
                    this.refreshToken();
                }
            return false;
        }
        return false;
    }

    refreshToken = async () => {
        const response = await axios.post('/api/users/login', {});
        if (response.data.success) {
            this.setToken(response.data)
        }
    }

    setToken(idToken) {
        localStorage.setItem('token', JSON.stringify(idToken.token));
        delete idToken.token;
        idToken.sessionTimeOut = moment(new Date()).add(30, 'm').toDate();
        localStorage.setItem('id_token', JSON.stringify(idToken));
        return true;
    }

    getToken() {
        var d = localStorage.getItem('id_token');
        return d != null && 'null' != d && undefined != d && 'undefined' != d ? JSON.parse(d) : false;
    }
    getLocaltoken() {
        var d = localStorage.getItem('token');
        return d != null && 'null' != d && undefined != d && 'undefined' != d ? JSON.parse(d) : false;
    }

    validateToken = async () => {
        var d = localStorage.getItem('token');
        if (d != null && 'null' != d && undefined != d && 'undefined' != d) {
            try {
                const response = await axios.post('/api/users/validateToken', {
                    token: JSON.parse(d)
                });
                if (!response.data.data.success) {
                    this.logout();
                }
            } catch (e) {
                this.logout();
                return false;
            }
            return false;
        } return false;
    }

    authCrossTab = async (status) => {
        if (status) {
            localStorage.setItem('id_token', localStorage.getItem('id_token'));
            window.location.href = '/';
        }
        else return await this.validateToken();
    }

    logout() {
        localStorage.removeItem('id_token');
        localStorage.removeItem('token');
        localStorage.removeItem('window_time');
        localStorage.removeItem('loggedInID');
        cookies.remove('startDate');
        cookies.remove('endDate');
        cookies.remove('dealer');
        cookies.remove('dealerValue');
        cookies.remove('searchBy');
        cookies.remove('searchByValue');
        cookies.remove('invoicenumber');
        StoreService.removeStore();
        return true;
    }

    getProfile() {
        var d = localStorage.getItem('id_token');
        return d != null && 'null' != d && undefined != d && 'undefined' != d ? JSON.parse(d) : false;
    }

  
}