import { combineReducers } from 'redux';
/* Import reducers and combine them below */
import dealerReducer from './dealer';
import productReducer from './newproduct';
import workorderReducer from './workorder';
import surveyReducer from './survey';
import vendorReducer from './vendor';
import { reducer as formReducer } from 'redux-form'
import { reducer as notifications } from 'react-notification-system-redux';
import authReducer from './user';
import statusReducer from './status';
import dashboardReducer from './dashboard'
import reportsReducer from './reports'
import lotwashReducer from './lotwash'
import hospitalReducer from './hospital'
import holidayReducer from './holiday';
import positionReducer from './position';
import empWagesReducer from './empEages'
import serviceDataReducer from './service_data_download'
import serviceCaptureReducer from './service_capture'
import purchaseorderReducer from './purchaseorder';
import WorkersErrorReducer from './workersError';
import schedulerReducer from './schedulerReducer';
import worksClaimReducer from './workersClaim';
import { reducer as IncidentReducer } from "./incident";
import { reducer as AccidentReducer } from "./accident";
import employeeReducer from "./employee";
import safetyPolicyReducer from "./safetyPolicy";
import invoiceReducer from "./searchInvice";
import mastersReducer from "./masters_add_update";

import clockApprovalReducer from './clockApproval'
import accidentalReportReducer from './accidentalReport'
import leadReducer from './leadActivity';
import vendorCheckinReducer from './vendorCheckin';
import formDigitilizationReducer from './formDigitilization';
import visitorCardReducers from './visitorCard';



export default combineReducers({
  dealerReducer,
  productReducer,
  workorderReducer,
  vendorReducer,
  form: formReducer,
  surveyReducer,
  mastersReducer,
  schedulerReducer,
  incident: IncidentReducer,
  accident: AccidentReducer,
  formErrors: WorkersErrorReducer,
  serviceCaptureReducer,
  notifications,
  worksClaimReducer,
  authReducer,
  statusReducer,
  dashboardReducer,
  reportsReducer,
  lotwashReducer,
  purchaseorderReducer,
  hospitalReducer,
  holidayReducer,
  positionReducer,
  serviceDataReducer,
  employeeReducer,
  safetyPolicyReducer,
  invoiceReducer,
  vendorCheckinReducer,
  empWagesReducer,
  clockApprovalReducer,
  accidentalReportReducer,
  leadReducer,
  vendorCheckinReducer,
  formDigitilizationReducer,
  visitorCardReducers
});