import {GET_CARD_LIST, GET_CARD_LIST_By_ID, GET_CARD_COMMENTS, GET_CARD_DASHBOARD_COUNT, GET_CARD_DASHBOARD_GRAPH_DATA} from '../../constants/Actiontypes'
const initialState = { getAllCardList: [], getAllCardListById: [], getComments: [], getCardDashboardCount:[], getCardGraphData:[]};

const visitorCardReducers = (state = initialState, action) => {
    switch(action.type) {
        case GET_CARD_LIST:
            return { ...state, getAllCardList: [...action.Payload] }
        case GET_CARD_LIST_By_ID:
            return { ...state, getAllCardListById: [...action.Payload] }
        case GET_CARD_COMMENTS:
            return { ...state, getComments: [...action.Payload] }
        case GET_CARD_DASHBOARD_COUNT:
            return { ...state, getCardDashboardCount: [...action.Payload] }
        case GET_CARD_DASHBOARD_GRAPH_DATA:
            return { ...state, getCardGraphData: [...action.Payload] }
        default:
        return state;
    }
}
export default visitorCardReducers