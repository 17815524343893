import {
    GET_SERVICE_VENDORS, GET_SERVICE_LIST_BY_ID, GET_SERVICE_LIST, GET_SERVICE_LIST_ADMIN,
    GET_EXPORT_BY_ID, GET_USER_LIST, GET_ALL_ROLES, GET_USER_DATA_ROLES, GET_USER_DATA_BY_ID,
    GET_AREA_MANGER_DATA, GET_AREA_MANAGER_LIST_WITH_LOCATION
} from '../../constants/Actiontypes';

const initialState = {
    serviceVendors: [], serviceListById: [], serviceList: [], exportList: [], serviceListAdmin: [],
    userList: [], roleList: [], usersDropdownList: [], userData: [], areaMangerList: [], areaMangerListWithLoc: []
};
const lotwashReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_VENDORS:
            return { ...state, serviceVendors: [...action.Payload] }
        case GET_SERVICE_LIST:
            return { ...state, serviceList: [...action.Payload] }
        case GET_SERVICE_LIST_ADMIN:
            return { ...state, serviceListAdmin: [...action.Payload] }
        case GET_SERVICE_LIST_BY_ID:
            return { ...state, serviceListById: [...action.Payload.list] }
        case GET_EXPORT_BY_ID:
            return { ...state, exportList: [...action.Payload] }
        case GET_USER_LIST:
            return { ...state, userList: [...action.Payload] }
        case GET_ALL_ROLES:
            return { ...state, roleList: [...action.Payload] }
        case GET_USER_DATA_ROLES:
            return { ...state, usersDropdownList: [...action.Payload] }
        case GET_AREA_MANGER_DATA:
            return { ...state, areaMangerList: [...action.Payload] }
        case GET_USER_DATA_BY_ID:
            return { ...state, userData: [...action.Payload] }
        case GET_AREA_MANAGER_LIST_WITH_LOCATION:
            return { ...state, areaMangerListWithLoc: [...action.Payload] }
        default:
            return state;
    }
};
export default lotwashReducer;