import {
    DASHBOARD_COUNT, DASBOARD_BACKLOG_LIST, DASHBOARD_CHART, DASHBOARD_INCIDENT_LIST
    , DASHBOARD_ACCIDENT_LIST, DASHBOARD_WORKERS_LIST, GET_LEAD_DASHBOARD_COUNT,
    GET_LEAD_GRAPH_COUNT, GET_LEAD_COUNT_BY_SALES_REP
} from '../../constants/Actiontypes'

const initialState = {
    dashboardCount: [], dashboardWorkOrderList: [], dashboardBacklogList: [], dashboardChart: [],
    dashboardIncidentList: [], dashboardAccidentList: [], dashboardWorkersList: [],
    dashboardLeadCount: [], dashboardLeadGraphCount: [], dashboardLeadCountbySales: []
};
const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_COUNT:
            return { ...state, dashboardCount: [...action.Payload] }
        case DASBOARD_BACKLOG_LIST:
            return { ...state, dashboardBacklogList: [...action.Payload] }
        case DASHBOARD_CHART:
            return { ...state, dashboardChart: [...action.Payload] }
        case DASHBOARD_INCIDENT_LIST:
            return { ...state, dashboardIncidentList: [...action.Payload] }
        case DASHBOARD_ACCIDENT_LIST:
            return { ...state, dashboardAccidentList: [...action.Payload] }
        case DASHBOARD_WORKERS_LIST:
            return { ...state, dashboardWorkersList: [...action.Payload] }
        case GET_LEAD_DASHBOARD_COUNT:
            return { ...state, dashboardLeadCount: [...action.Payload] }
        case GET_LEAD_GRAPH_COUNT:
            return { ...state, dashboardLeadGraphCount: [...action.Payload] }
        case GET_LEAD_COUNT_BY_SALES_REP:
            return { ...state, dashboardLeadCountbySales: [...action.Payload] }
        default:
            return state;
    }
}

export default dashboardReducer;
