import { PO_TOTAL_COUNT, PURCHASEORDER_LIST, GENERATE_PO_DETAILS, PURCHASEORDER_VIEW, RECENT_PO } from '../../constants/Actiontypes';

const initialState = {
    purchaseOrderDetails: {
        purchaseOrderList: [],
        totalCount: 0,
    },
    purchaseOrderView: {
        purchaseOrderData: [],
        purchaseOrderDataTable: [],
        workOrders: [],
        AllVendors: [],
        Vendors: [],
        dataVendorwisTotal: [],
        recentpo: []
    },
    generatePODetails: {},

};

const purchaseorderReducer = (state = initialState, action) => {
    switch (action.type) {
        case PURCHASEORDER_LIST:
            return { ...state, purchaseOrderDetails: action.Payload }
        case PURCHASEORDER_VIEW:
            return {
                ...state,
                purchaseOrderView: { ...action.Payload }
            }
        case PO_TOTAL_COUNT:
            return {
                ...state, totalCount: action.Payload
            }
        case GENERATE_PO_DETAILS:
            return { ...state, generatePODetails: { ...action.Payload } }
        case RECENT_PO: 
            return { ...state, recentpo: [...action.Payload] }
        default:
            return state;
    }
}

export default purchaseorderReducer;