import {
    STATUSGET_DROPDOWN, STATUS_PURCHASEORDER, STATUS_CLAIMPORTAL, STATUS_CLAIM_PORTAL,
    STATUS_WORKER_CLAIM_PORTAL
} from '../../constants/Actiontypes'

const initialState = {
    getStatusDropdown: [],
    getStatusPurchaseOrder: [],
    getStatusClaimportal: [],
    getStatusDashboardClaimportal: [],
    getStatusWorkersClaimportal: [],
};

const statusReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATUSGET_DROPDOWN:
            return { ...state, getStatusDropdown: [...action.Payload] }

        case STATUS_PURCHASEORDER:
            return { ...state, getStatusPurchaseOrder: [...action.Payload] }

        case STATUS_CLAIM_PORTAL:
            return { ...state, getStatusDashboardClaimportal: [...action.Payload] }

        case STATUS_CLAIMPORTAL:
            return { ...state, getStatusClaimportal: [...action.Payload] }
        case STATUS_WORKER_CLAIM_PORTAL:
            return { ...state, getStatusWorkersClaimportal: [...action.Payload] }
        default:
            return state;
    }
}

export default statusReducer;
