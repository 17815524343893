import { GET_CLOCKAPPROVAL_BY_ID, FETCH_CLOCKAPPROVAL_LIST } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getclockApprovalList: [] , getclockApprovalById: []};

const clockApprovalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CLOCKAPPROVAL_LIST:
            return { ...state, getclockApprovalList: [...action.Payload] }
        case GET_CLOCKAPPROVAL_BY_ID:
            return {...state,getclockApprovalById:[...action.Payload]}
        default:
            return state;
    }
};

export default clockApprovalReducer;