import React, { Component } from "react";
import axios from '../../helpers/axiosInstance';
import { Row, Col } from 'react-bootstrap';


class DocuSignRedirect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }


  async componentDidMount() {
    // http://localhost:3000/docusign-redirect?env=545aca5b-914d-4e00-82a1-079e308dcf7b&pf=7bf9ccb3-7ae6-422a-9503-85308d9dc1d2&r=6ff9e29d-47b0-4c4e-b971-f124f27c70ff
    console.log(window.location.search);

    let url = window.location.search;
    let envelope = '';

    try {

      const { location: { search } } = this.props;

      let id = search.slice(search.indexOf('=') + 1, search.indexOf('&'));

      let params = {}, _storage = '';
      _storage = await localStorage.getItem('@docuData');

      console.log(_storage)

      if (_storage && _storage != null && _storage != 'undefined' && _storage != '') {
        params = JSON.parse(_storage);
      }

      console.log(params);

      if (params.hasOwnProperty('DocuStatus')) {
        let responce = await axios.post('api/docusign/downloadAttachments/' + id, params);
        console.log(responce)

        if (responce && responce.data && responce.data.hasOwnProperty('success')) {
          localStorage.removeItem('@docuData');
          this.setState({ loading: false });
        } else {
          console.log('loading failed respoce', responce)
          this.setState({ loading: false });
        }
      } else {
        console.log('loading failed Docu status', params.hasOwnProperty('DocuStatus'))
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="content access-denied-container">
        <Row>
          <Col md={12}>
            {
              loading &&
              <div className="docu-thanks">
                <h1>Please Wait...</h1>
                <h6>we are processing your request</h6>
              </div>
            }
            {
              loading === false &&
              <div className="docu-thanks">
                <h1>Thanks</h1>
                <h6>You can close the window</h6>
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  }
}




export default DocuSignRedirect;