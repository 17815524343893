import { FETCH_CHECKED_VENDOR_LIST,FETCH_DUP_CHECKED_VENDOR_LIST, FETCH_CHECKED_VENDOR_LIST_ALL, GET_VENDOR_LIST_DROPDOEN, GET_VENDOR_BY_ID, GET_VENDOR_FOR_EXPORT } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getCheckedVendorList: [],getCheckedVendorDupList:[] , getCheckedVendorListAll: [], getVendorDropDwon: [], getVendorById: [], getVendorForExport: []};

const vendorCheckinReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHECKED_VENDOR_LIST:
            return { ...state, getCheckedVendorList: [...action.Payload] }
        case FETCH_DUP_CHECKED_VENDOR_LIST:
            return { ...state, getCheckedVendorDupList: [...action.Payload] }
        case FETCH_CHECKED_VENDOR_LIST_ALL:
            return { ...state, getCheckedVendorListAll: [...action.Payload] }
        case GET_VENDOR_LIST_DROPDOEN:
            return { ...state, getVendorDropDwon: [...action.Payload] }
        case GET_VENDOR_BY_ID:
            return { ...state, getVendorById: [...action.Payload] }
        case GET_VENDOR_FOR_EXPORT:
            return { ...state, getVendorForExport: [...action.Payload] }
        default:
            return state;
    }
};

export default vendorCheckinReducer;