import {
    FETCH_ACCIDENT_LIST,
    FETCH_ACCIDENT,
    VIN_DECODE,
} from '../../constants/Actiontypes';

export const accidentActions = {
    getAccidentList: (dispatch, accidents) => {
        dispatch({ type: FETCH_ACCIDENT_LIST, accidents });
    },
    getAccident: (dispatch, accident) => {
        dispatch({ type: FETCH_ACCIDENT, accident });
    },
    VINDecode: (dispatch, accident) => {
        dispatch({ type: VIN_DECODE, accident });
    }
};

const initialState = {
    list: [],
    accident: {},
};

export const reducer = (state = initialState, action) => {
    // console.log(action.type, action)
    const { accidents, accident } = action;
    switch (action.type) {
        case FETCH_ACCIDENT_LIST:
            return {
                ...state,
                list: accidents
            };

        case FETCH_ACCIDENT:
            return {
                ...state,
                accident: accident
            };
        case VIN_DECODE:
            return {
                ...state,
                accident: accident
            };
        default:
            return state;
    }
};
