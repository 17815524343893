import { GET_FORM_DIGITILIZATION_LIST, GET_INSURANCE_DETAILS } from '../../constants/Actiontypes'

const initialState = {
    getFormList : [],
    getInsuranceDetails: []
}

const formDigitilizationReducer  = (state = initialState, action) => {
    switch (action.type) {
        case GET_FORM_DIGITILIZATION_LIST: 
            return {...state, getFormList:[...action.Payload] }
        case GET_INSURANCE_DETAILS: 
            return {...state, getInsuranceDetails:[...action.Payload]}
        default: 
            return state;
    }
};

export default formDigitilizationReducer;