import { FETCH_HOSPITAL_LIST, GET_HOSPITAL_BY_ID } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getHospitalList: [] , getHospitalById: []};

const hospitalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOSPITAL_LIST:
            return { ...state, getHospitalList: [...action.Payload] }
        case GET_HOSPITAL_BY_ID:
            return {...state,getHospitalById:[...action.Payload]}
        default:
            return state;
    }
};

export default hospitalReducer;