import { FETCH_HOLIDAY_LIST, GET_HOLIDAY_BY_ID } from '../../constants/Actiontypes'
import { reducer } from 'redux-form';

const initialState = { getHolidayList: [] , getHolidayById: []};

const holidayReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOLIDAY_LIST:
            return { ...state, getHolidayList: [...action.Payload] }
        case GET_HOLIDAY_BY_ID:
            return {...state,getHolidayById:[...action.Payload]}
        default:
            return state;
    }
};

export default holidayReducer;