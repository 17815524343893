import { FETCH_ERRORS } from '../../constants/Actiontypes';

const initialState = { workersError: [] };
const WorkersErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ERRORS:
            return { ...state, workersError: [action.Payload] }
        default:
            return state;
    }
}

export default WorkersErrorReducer;
