import {
    LOAD_ALL_SERVICE_CAPTURE_LIST
    , PERTICULAR_DEALER_SERVICES
    , LOAD_DUBLICATE_VIN_COMMENTS
    , LOAD_COMPLETED_INVOICE_BY_ID
} from '../../constants/Actiontypes';

import { reducer } from 'redux-form';

const initialState = { list: [], users_session: {}, completed_invoice: {}, completed_invoice_list: [], dealerList: [], dublicateVIN: [] };

const serviceCaptureReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ALL_SERVICE_CAPTURE_LIST:
            return {
                ...state,
                list: [...action.Payload.data],
                users_session: { ...action.Payload.users_session }
            }

        case PERTICULAR_DEALER_SERVICES:
            return {
                ...state,
                dealerList: [...action.Payload]
            }

        case LOAD_DUBLICATE_VIN_COMMENTS:
            return {
                ...state,
                dublicateVIN: [...action.Payload]
            }

        case LOAD_COMPLETED_INVOICE_BY_ID:
            return {
                ...state,
                completed_invoice: { ...action.Payload.data },
                completed_invoice_list: [...action.Payload.list],
                users_session: { ...action.Payload.users_session }
            }


        default:
            return state;
    }
};

export default serviceCaptureReducer;