import moment from 'moment';
 /**
         * <h5>Summary of Local Storage: </h5>
         * Provides function to access local storage
         * <ol>
         *      <li> setIntoStore : set information (Stringified object ) to local storage</li>
         *      <li> removeStore : Removed "location" from local storage</li>
         *      <li> getLocations : Access location from local storage</li>
         * </ol>
         * <h6>Last Modified: <small> Dhileep Kumar </small> </h6>
         * <h6>Last updated by: <small> 30/09/2019 </small> </h6>
         * <h6>Reason for modify: <small>To create doc for Download LocalStore</small> </h6>
*/

export default class LocalStore {
    constructor() {
        this.getLocations = this.getLocations.bind(this)
    }

    setIntoStore(lable, data) {
        localStorage.setItem(lable, JSON.stringify(data));
        return true;
    }

    removeStore() {
        localStorage.removeItem('location');
        return true;
    }

    getLocations() {
        var d = localStorage.getItem('location');
        return d != null && 'null' != d && undefined != d && 'undefined' != d ? JSON.parse(d) : false;
    }
}