import { CATEGORY, UNIT, VENDAR, PRODUCTS, PRODUCT_DETAILS, SUPPLYITEM } from '../../constants/Actiontypes';

const initialState = { category: [], unit: [], vendors: [], vendardate: {}, products: [], productdetails: {}, productprice: [], getSupplyItemAction: [] };
const productReducer = (state = initialState, action) => {

    switch (action.type) {
        case CATEGORY:
            return { ...state, category: [...action.Payload] }
        case UNIT:
            return { ...state, unit: [...action.Payload] }
        case VENDAR:
            return { ...state, vendors: [...action.Payload] }
        case PRODUCTS:
            return { ...state, products: [...action.Payload] }
        case PRODUCT_DETAILS:
            return { ...state, productdetails: { ...action.Payload } }
        case SUPPLYITEM:
            return { ...state, getSupplyItemAction: [ ...action.Payload ] }
        default:
            return state;
    }
};

export default productReducer;