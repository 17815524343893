import { SURVEY_LIST, GET_ONE_SURVEY, USER_LIST, SURVEY_EMAIL_TEMPLATES, TAKE_SURVEY, SURVEY_REPORT} from '../../constants/Actiontypes';

const initialState = {surveylist:[],surveydetails:{}};
const surveyReducer = (state = initialState, action) => {
    switch (action.type) {  
    case SURVEY_LIST:
        return {...state,surveylist:[...action.Payload]};
    case USER_LIST:
        return {...state,userlist:[...action.Payload]};
    case SURVEY_EMAIL_TEMPLATES:
        return {...state,emailTemplates:[...action.Payload]};
    case GET_ONE_SURVEY:
        return {...state,surveydetails:action.Payload}
    case TAKE_SURVEY:
        return {...state,takesurveydetails:action.Payload}
    case SURVEY_REPORT:
        return {...state,surveyReport:action.Payload}
    default:
        return state;
    }
};
export default surveyReducer;